<template>
  <div class="box">
  </div>
</template>

<script>

export default {
  name: "Product",
  components:{
    
  },
  setup() {

    return {

    };
  },
};
</script>

<style lang="scss" scoped>
.box {
  padding: 0.4rem 0;
  background: url(http://filseclab.com/images/product_bk.jpg) no-repeat center
    top;
}
.product {
  width: 12rem;
  margin: auto;
  font-size: 0;
}
.flash {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .4rem;
  & > div {
    width: 49%;
    img {
      width: 100%;
    }
  }
  .second {
    box-sizing: border-box;
    padding-left: 0.2rem;
  }
  h3 {
    font-size: 0.26rem;
    color: #363636;
    font-weight: normal;
    margin-bottom: 0.1rem;
    sup {
      font-size: 70%;
      color: #ff9900;
    }
  }
  p {
    line-height: 0.3rem;
    font-size: 0.18rem;
    .el-icon-check {
      color: #8ab402;
      font-weight: bolder;
    }
    &:nth-last-of-type(1) {
      margin-bottom: 0.15rem;
    }
  }
}
.what{
  h4{
    font-size: .22rem;
    color: #363636;
    font-weight: normal;
  }
  p{
    font-size: .16rem;
  }
  .el-image{
    margin-left: .15rem;
  }
  .more{
    font-size: .16rem;
    color: #e87f00;
    text-align: right;
    span{
      cursor: pointer;
    }
  }
  .vb{
    padding-left: .35rem;
    background: url(http://filseclab.com/images/quote_l.gif) no-repeat left top/.24rem;
    p{
      font-size: .12rem;
      color: #464646;
      line-height: .2rem;
      &:nth-of-type(2){
        margin-bottom: .1rem;
      }
    }
    .speaker{
      // height: .48rem;
      // line-height: .48rem;
      font-size: .12rem;
      color: #7d7d7d;
      text-align: right;
      margin-bottom: .2rem;
      img{
        // height: 100%;
        vertical-align: middle;
      }
    }
  }
  .share{
    .desc{
      font-size: .12rem;
      color: #7d7d7d;
    }
  }
}
.mb{
  margin-bottom: .6rem;
}
</style>